import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { DeleteModalInterface } from "./interfaces/interface";

const DeleteModal: FC<DeleteModalInterface> = ({
  isOpen = false,
  handleClose,
  deleteErrorMessage = "",
  handleOnDelete,
  loading = false,
  title = "Once it is deleted you will not be able to recover the data again!",
  buttonName = "Delete",
}) => {
  const theme = useTheme();
  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div className="modalHead">
          <span> Are you sure?</span>
          <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
      </DialogTitle>
      {deleteErrorMessage && (
        <Alert severity="error">{deleteErrorMessage}</Alert>
      )}
      <DialogContent>
        <DialogContentText>{title}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ marginBottom: "20px", justifyContent: "center" }}>
        <Button
          color="primary"
          variant="outlined"
          sx={{
            borderColor: `${
              theme.palette.mode === "dark" ? "primary.light" : "primary.main"
            }`,
            color: `${
              theme.palette.mode === "dark" ? "primary.light" : "primary.main"
            }`,
            "&:hover": {
              borderColor: `${
                theme.palette.mode === "dark" ? "primary.dark" : "primary.main"
              }`,
              color: `${
                theme.palette.mode === "dark" ? "primary.dark" : "primary.main"
              }`,
            },
          }}
          type="button"
          size="large"
          style={{ width: "270px" }}
          className="loginButton"
          disabled={loading}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          style={{ width: "270px" }}
          type="button"
          size="large"
          onClick={handleOnDelete}
          // disabled={loading}
          //TODO: remove this line when the delete functionality is implemented and add device functionality
          disabled={true}
          className="loginButton"
        >
          {buttonName}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
