import AnalyticsGrey from "../../assets/images/sidebar/analysticGrey.svg";
import AnalyticsWhite from "../../assets/images/sidebar/analysticWhite.svg";
import ChannelGrey from "../../assets/images/sidebar/channelGrey.svg";
import ChannelWhite from "../../assets/images/sidebar/channelWhite.svg";
import DeviceGrey from "../../assets/images/sidebar/devicesGrey.svg";
import DeviceWhite from "../../assets/images/sidebar/devicesWhite.svg";
import MatchesGrey from "../../assets/images/sidebar/matchesGrey.svg";
import MatchesWhite from "../../assets/images/sidebar/matchesWhite.svg";
import UserGrey from "../../assets/images/sidebar/usersGrey.svg";
import UserWhite from "../../assets/images/sidebar/usersWhite.svg";
import ROUTE_URLS from "../../config/routes";

interface Menus {
  title: string;
  iconGrey: string;
  iconWhite: string;
  icon: any;
  link: string;
  role: string;
}

export type MenusInterface = Menus[];

export const SidebarMenu: MenusInterface = [
  {
    title: "Devices",
    icon: "DevicesIcon",
    iconGrey: DeviceGrey,
    iconWhite: DeviceWhite,
    link: ROUTE_URLS.DEVICE_MANAGEMENT,
    role: "user",
  },
  {
    title: "Channel",
    icon: "CameraAltOutlined",
    iconGrey: ChannelGrey,
    iconWhite: ChannelWhite,
    link: ROUTE_URLS.CHANNEL_MANAGEMENT,
    role: "user",
  },
  // {
  //   title: "Pairs",
  //   icon: "Merge",
  //   iconGrey: PairGrey,
  //   iconWhite: PairWhite,
  //   link: ROUTE_URLS.PAIRS_MANAGEMENT,
  //   role: "user",
  // },
  // {
  //   title: "BroadCast",
  //   icon: "VideocamIcon",
  //   iconGrey: BroadcastGrey,
  //   iconWhite: BroadcastWhite,
  //   link: ROUTE_URLS.BROADCAST_MANAGEMENT,
  //   role: "user",
  // },
  {
    title: "Users",
    icon: "CameraAltOutlined",
    iconGrey: UserGrey,
    iconWhite: UserWhite,
    link: ROUTE_URLS.USERS,
    role: "admin",
  },
  {
    title: "Analytics",
    icon: "DevicesIcon",
    iconGrey: AnalyticsGrey,
    iconWhite: AnalyticsWhite,
    link: ROUTE_URLS.ANALYTICS,
    role: "admin",
  },
  {
    title: "Matches",
    icon: "SportsEsportsIcon",
    iconGrey: MatchesGrey,
    iconWhite: MatchesWhite,
    link: ROUTE_URLS.MATCHES,
    role: "user",
  },
];

export const settings: MenusInterface = [
  // {
  // 	title: "Setting",
  // 	link: ROUTE_URLS.UPDATE_PROFILE,
  // 	iconGrey: "",
  // 	iconWhite: "",
  // 	icon: SettingsIcon,
  // 	role: "any",
  // },
];
