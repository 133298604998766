import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { useEffect, useState } from "react";
import { get } from "../../../helper";

interface Stream {
  name: string;
  playbackUrl: string;
}

interface Match {
  _id: string;
  matchName: string;
  startTime: string;
  endTime: string;
  status: string;
  streams: Stream[];
  activeStatus: string;
}

const Matches = () => {
  const [activeScreens, setActiveScreens] = useState<number>(4);
  const [screenStreams, setScreenStreams] = useState<(number | null)[]>([
    0, 1, 2, 3,
  ]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [match, setMatch] = useState<Match | null>(null);

  useEffect(() => {
    const fetchMatch = async () => {
      try {
        const response = await get("/matches/678a53b01f7df50994482514");
        setMatch(response.data);

        // Initialize based on available streams count (max 4)
        const streamCount = Math.min(response.data.streams.length, 4);
        setActiveScreens(streamCount);

        // Initialize streams array with available indices
        const initialStreams = new Array(4).fill(null);
        for (let i = 0; i < streamCount; i++) {
          initialStreams[i] = i;
        }
        setScreenStreams(initialStreams);
      } catch (error) {
        console.error("Error fetching match:", error);
      }
    };

    fetchMatch();
  }, []);

  const handleStreamSelect = (
    screenIndex: number,
    streamIndex: number | null
  ) => {
    const newScreenStreams = [...screenStreams];
    newScreenStreams[screenIndex] = streamIndex;
    setScreenStreams(newScreenStreams);
  };

  const handleScreenCountChange = (count: number) => {
    setActiveScreens(count);
    // Keep existing stream selections when changing screen count
    const newStreams = [...screenStreams];
    setScreenStreams(newStreams);
  };

  const getAvailableStreams = (screenIndex: number) => {
    if (!match) return [];

    // Return all streams for selection
    return match.streams;
  };

  const getFormattedStreamUrl = (url: string) => {
    if (url.includes("youtube.com/live/")) {
      // Convert youtube.com/live/ID to youtube.com/embed/ID format
      const videoId = url.split("/live/")[1].split("?")[0];
      return `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`;
    } else if (url.includes("youtube.com/watch?v=")) {
      // Convert youtube.com/watch?v=ID to youtube.com/embed/ID format
      const videoId = url.split("watch?v=")[1].split("&")[0];
      return `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`;
    }
    return url;
  };

  return (
    <>
      <h1 className="pageHeading">Matches</h1>
      <Box sx={{ width: "100%", position: "relative" }}>
        <IconButton
          onClick={() => setIsFullscreen(!isFullscreen)}
          sx={{
            position: "absolute",
            top: -45,
            right: 0,
            color: "primary.main",
            zIndex: isFullscreen ? 1301 : 2,
            bgcolor: isFullscreen ? "background.paper" : "transparent",
            "&:hover": {
              bgcolor: isFullscreen ? "background.default" : "transparent",
            },
            ...(isFullscreen && {
              position: "fixed",
              top: 16,
              right: 16,
              boxShadow: 2,
            }),
          }}
        >
          {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>

        <Paper
          sx={{
            width: "100%",
            mb: 2,
            p: 2,
            ...(isFullscreen && {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1300,
              m: 0,
              p: 2,
              borderRadius: 0,
              overflow: "auto",
              bgcolor: "background.default",
              display: "flex",
              flexDirection: "column",
            }),
          }}
        >
          <Box sx={{ mb: 2, display: "flex", gap: 2, flexWrap: "wrap" }}>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel>Screen Count</InputLabel>
              <Select
                value={activeScreens}
                label="Screen Count"
                onChange={(e) =>
                  handleScreenCountChange(Number(e.target.value))
                }
              >
                {/* Dynamic screen count options based on available streams */}
                {match?.streams.length &&
                  [...Array(Math.min(match.streams.length, 4))].map(
                    (_, idx) => (
                      <MenuItem key={idx + 1} value={idx + 1}>
                        {idx + 1} {idx === 0 ? "Screen" : "Screens"}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>

            {/* Show stream selection only when not in 4-screen mode */}
            {activeScreens !== 4 &&
              [...Array(activeScreens)].map((_, index) => (
                <FormControl key={index} sx={{ minWidth: 120 }}>
                  <InputLabel>Screen {index + 1}</InputLabel>
                  <Select
                    value={screenStreams[index] ?? ""}
                    label={`Screen ${index + 1}`}
                    onChange={(e) =>
                      handleStreamSelect(
                        index,
                        e.target.value === "" ? null : Number(e.target.value)
                      )
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {getAvailableStreams(index).map((stream, idx: number) => (
                      <MenuItem key={stream.name} value={idx}>
                        {stream.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ))}
          </Box>

          <Grid
            container
            spacing={2}
            sx={{
              flexGrow: 1,
              ...(isFullscreen && {
                height: "calc(100vh - 120px)", // Account for controls and padding
                alignContent: "center",
              }),
            }}
          >
            {[...Array(activeScreens)].map((_, index) => (
              <Grid
                item
                xs={12}
                md={activeScreens === 1 ? 12 : 6}
                sx={{
                  height: activeScreens <= 2 ? "100%" : "auto",
                }}
              >
                <Paper
                  sx={{
                    backgroundColor: "black",
                    aspectRatio: "16/9",
                    width: "100%",
                    height: isFullscreen ? "100%" : "auto",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  {screenStreams[index] !== null ? (
                    <>
                      <Box
                        sx={{
                          position: "absolute",
                          top: 10,
                          left: 10,
                          color: "white",
                          zIndex: 2,
                          backgroundColor: "rgba(0,0,0,0.5)",
                          padding: "4px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        {match?.streams[screenStreams[index]!].name}
                      </Box>
                      <iframe
                        src={
                          match?.streams[screenStreams[index]!].playbackUrl
                            ? getFormattedStreamUrl(
                                match.streams[screenStreams[index]!].playbackUrl
                              )
                            : ""
                        }
                        width="100%"
                        height="100%"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{ position: "absolute", top: 0, left: 0 }}
                        title={`Stream ${index + 1} - ${match?.streams[
                          screenStreams[index]!
                        ].name}`}
                      />
                    </>
                  ) : (
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "grey.500",
                      }}
                    >
                      Select a stream
                    </Box>
                  )}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default Matches;
